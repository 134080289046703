<template>
  <PrimePage
    titleAlignment="left"
    :backToNamedRoute="'patientView'"
    :backText="'Back to Conversations'"
  >
    <ErrorNotification :errors="errors" class="pad-twenty" />
    <ion-row class="display-flex valign pad-twenty">
      <ion-col>
        <h3 class="margin-left-twenty">Send Broadcast Message</h3>
      </ion-col>
      <ion-col></ion-col>
      <ion-col size="auto" class="margin-lr-ten">
        <button
          class="prime-button button-primary button-block"
          @click="goToNamedRoute('BroadcastMessageHistory')"
        >
          Broadcast Message History
        </button>
      </ion-col>
    </ion-row>
    <!-- Step One -->
    <ion-card v-if="stepNumber == 1" color="light" class="padding-death split-screen-card">
      <ion-row>
        <!-- {{ stagedPatients }} -->
        <ion-col class="column-stretch-wrap">
          <div class="column-stretch-header pad-top-twenty pad-lr-twenty pad-bottom-ten">
            <h6 class="margin-top-zero">Find Patient to Include</h6>
            <ion-row v-if="!areAllPatientsStaged" class="margin-top-ten">
              <ion-col>
                <ion-segment
                  @ionChange="changeAddByMode($event)"
                  :value="addByMode"
                  mode="ios"
                  class="prime-channel-tabs-container margin-top-ten"
                >
                  <ion-segment-button
                    layout="icon-start"
                    value="patient"
                    class="prime-channel-tab"
                    mode="ios"
                  >
                    Search for Patient
                  </ion-segment-button>
                  <ion-segment-button layout="icon-start" value="program" mode="ios">
                    Add by Program
                  </ion-segment-button>
                  <ion-segment-button layout="icon-start" value="bulk" mode="ios">
                    Bulk Add
                  </ion-segment-button>
                </ion-segment>
              </ion-col>
            </ion-row>
          </div>
          <div v-if="allLoading" class="text-center pad-ten pad-thirty center-center">
            <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
          </div>
          <div
            v-else-if="addByMode == 'patient'"
            class="column-stretch-body add-patient-select-container"
          >
            <form
              v-if="!areAllPatientsStaged"
              @submit.prevent
              novalidate="true"
              class="margin-top-ten margin-bottom-ten pad-lr-twenty"
            >
              <ion-row>
                <ion-col>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for patient by name or ID"
                    v-model="searchString"
                  />
                </ion-col>
              </ion-row>
            </form>
            <div
              v-if="!isListLoading && allPatients.length == 0"
              class="text-muted text-center center-center"
            >
              No patients found
            </div>
            <div v-if="isListLoading" class="text-center pad-ten pad-thirty center-center">
              <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
            </div>
            <div
              v-else-if="availablePatients.length > 0"
              class="scroll-vert pad-lr-twenty pad-top-ten"
            >
              <div v-if="!areAllPatientsStaged">
                <ion-card
                  v-if="numberOfPatients > 3 && !isSearching"
                  class="prime-patient-card ion-margin-bottom"
                >
                  <ion-row class="ion-align-items-center full-width pad-ten">
                    <ion-col size="auto">
                      <IconPeople class="margin-lr-fifteen icon-medium" />
                    </ion-col>
                    <ion-col>
                      <div class="prime-patient-card-name">
                        Add All Patients
                      </div>
                      <div class="prime-patient-card-info">
                        <span class="text-bold">Patients:</span
                        ><span class="margin-left-five">{{
                          numberOfPatients.toLocaleString()
                        }}</span>
                      </div>
                    </ion-col>
                    <ion-col size="auto" class="pad-right-ten">
                      <button
                        @click="stageAllPatients"
                        class="prime-button button-block button-secondary"
                      >
                        &plus; Add
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-card>
                <ion-card
                  v-for="(patient, key) in availablePatients"
                  :key="key"
                  class="prime-patient-card ion-margin-bottom"
                >
                  <ion-row class="ion-align-items-center full-width">
                    <ion-col size="auto">
                      <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                        {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                      </div>
                    </ion-col>
                    <ion-col>
                      <div class="prime-patient-card-name">
                        {{ patient.beneLastName + ", " + patient.beneFirstName }}
                      </div>
                      <div class="prime-patient-card-info">
                        <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
                        <div class="display-inline-block">
                          <strong>DOB: </strong>{{ patient.dob | moment("MM/DD/YYYY") }}
                        </div>
                      </div>
                    </ion-col>
                    <ion-col size="auto" class="pad-right-twenty">
                      <button
                        @click="stagePatient(patient, true)"
                        class="prime-button button-block button-secondary"
                      >
                        &plus; Add
                      </button>
                    </ion-col>
                  </ion-row>
                  <!-- <div class="prime-patient-card-icons">
                    <button @click="stagePatient(patient, true)" class="prime-button button-block button-secondary">&plus; Add</button>
                  </div> -->
                </ion-card>
              </div>
            </div>
            <div
              v-if="allPatients.length > 0 && !areAllPatientsStaged && !isSearching"
              class="column-stretch-footer text-muted text-center pad-bottom-ten"
            >
              <small
                >Showing {{ allPatients.length }} preview patients. Search will query all
                patients.</small
              >
            </div>
          </div>
          <div v-else-if="addByMode == 'program'" class="pad-lr-twenty scroll-vert">
            <div v-if="availablePrograms.length > 0">
              <ion-card
                v-for="(program, key) in availablePrograms"
                :key="key"
                class="prime-patient-card ion-margin-bottom pad-top-twenty pad-bottom-twenty"
              >
                <IconPrograms slot="start" class="margin-lr-fifteen icon-small" />
                <div>
                  <div class="prime-patient-card-name">
                    {{ program.name }}
                  </div>
                </div>
                <div class="prime-patient-card-icons">
                  <button
                    @click="stageProgram(program)"
                    class="prime-button button-block button-secondary"
                  >
                    &plus; Add
                  </button>
                </div>
              </ion-card>
            </div>
            <div v-else>
              <div
                class="centerItems text-center text-muted display-flex valign full-height margin-top-twenty full-width"
              >
                There are no program available
              </div>
            </div>
          </div>
          <div v-else class="column-stretch-body add-patient-select-container">
            <div class="column-stretch-header pad-twenty">
              <div v-if="!searchResults">
                Enter the ID of the patients you would like to add:
              </div>
              <div v-if="searchResults && searchResults.missingRecords">
                <ion-card
                  color="warning"
                  v-if="searchResults.missingRecords.length > 5"
                  class="margin-lr-five"
                >
                  <ion-row class="ion-align-items-center">
                    <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                      <IconWarning />
                    </ion-col>
                    <ion-col>
                      <ion-row class="text-bold">
                        {{
                          searchResults.missingRecordsCount
                            ? searchResults.missingRecordsCount
                            : "More than five "
                        }}
                        MRNs could not be found
                      </ion-row>
                      <ion-row v-if="searchResults.url">
                        <span
                          class="text-small link-dummy"
                          @click="downloadReport(searchResults.url)"
                          >Download the full report</span
                        >
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-card>
                <ion-card
                  color="warning"
                  v-for="(mrn, key) in searchResults.missingRecords"
                  :key="key"
                  class="margin-lr-five"
                >
                  <ion-row class="ion-align-items-center">
                    <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                      <IconWarning />
                    </ion-col>
                    <ion-col>
                      <ion-row class="text-bold">
                        {{ "ID: " + mrn }}
                      </ion-row>
                      <ion-row>
                        <span class="text-small">
                          {{ "Did not match a patient." }}
                        </span>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-card>
              </div>
            </div>
            <!-- {{ searchResults }} -->
            <div class="column-stretch-body add-patient-select-container pad-lr-twenty">
              <form
                v-if="!searchResults"
                @submit.prevent
                novalidate="true"
                class="margin-top-ten margin-bottom-ten full-height"
              >
                <ion-row class="full-height">
                  <ion-col class="full-height">
                    <textarea-autosize
                      maxlength="200000"
                      placeholder="Paste comma-separated or line-separated MRNs here...."
                      class="form-control prime-message-input mrn-textarea"
                      ref="outboundMessageInput"
                      rows="5"
                      v-model="inputMrns"
                      @keydown.native.enter.exact.prevent
                      @keydown.native.enter.exact="newline"
                    />
                  </ion-col>
                </ion-row>
              </form>
              <div v-if="searchResults" class="pad-top-five scroll-vert pad-lr-five">
                <div v-if="duplicateMrnsStagedPatients.length > 0" class="pad-bottom-ten">
                  <ion-card
                    color="warning"
                    v-for="dupMrnPatient in duplicateMrnsStagedPatients"
                    :key="dupMrnPatient.beneficiaryId"
                    class="margin-lr-five"
                  >
                    <ion-row class="ion-align-items-center">
                      <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                        <IconWarning />
                      </ion-col>
                      <ion-col>
                        <ion-row class="text-bold">
                          {{ dupMrnPatient.beneLastName + ", " + dupMrnPatient.beneFirstName }}
                        </ion-row>
                        <ion-row>
                          <span class="text-small">
                            {{
                              dupMrnPatient.duplicateMrn
                                ? `Duplicate ID: ${dupMrnPatient.duplicateMrn}`
                                : ""
                            }}
                          </span>
                        </ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-card>
                </div>
                <ion-card
                  v-for="(patient, key) in searchResults.results"
                  :key="key"
                  color="white"
                  class="prime-patient-card ion-margin-bottom"
                >
                  <ion-row>
                    <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                      {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                    </div>
                    <div class="pad-lr-ten">
                      <div class="prime-patient-card-name text-bold">
                        {{ patient.beneLastName + ", " + patient.beneFirstName }}
                      </div>
                      <div
                        class="prime-patient-card-info text-muted text-small"
                        v-if="patient.mrn || patient.dob_str"
                      >
                        <strong v-if="patient.mrn">ID: </strong>
                        <span class="mrn-holder">{{ patient.mrn }}</span>
                        <div class="display-inline-block" v-if="patient.dob_str">
                          <strong>DOB: </strong> {{ patient.dob_str }}
                        </div>
                      </div>
                    </div>
                  </ion-row>
                </ion-card>
              </div>
            </div>
            <div class="column-stretch-footer pad-lr-twenty pad-bottom-twenty">
              <div
                v-if="searchResults"
                class="text-center margin-bottom-ten margin-top-ten text-muted text-small"
              >
                <span class="text-bold"> {{ searchResults.missingRecordsCount }}</span> Error(s) -
                <span class="text-bold">{{ searchResults.results.length }}</span> Patient(s)
                Selected
              </div>
              <div v-if="searchResults && searchResults.results">
                <ion-row>
                  <ion-col class="m-r">
                    <button @click="resetState" class="prime-button button-secondary button-block">
                      <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
                      <span>Clear</span>
                    </button>
                  </ion-col>
                  <ion-col>
                    <button
                      v-if="searchResults.results.length > 0"
                      @click="addPatientsByMrns"
                      class="prime-button button-primary button-block"
                    >
                      <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
                      <span>Add {{ searchResults.results.length }} Patient(s)</span>
                    </button>
                  </ion-col>
                </ion-row>
              </div>
              <button
                v-else
                @click="processMrns"
                :class="{ 'button-pending': isProcessingMRNs }"
                :disabled="!inputMrns"
                class="prime-button button-primary button-block"
              >
                <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
                <span>Process MRNs</span>
              </button>
            </div>
          </div>
        </ion-col>

        <ion-col>
          <div class="column-stretch-wrap full-height bg-white border-left">
            <div class="column-stretch-header pad-twenty">
              <h6 class="margin-top-zero">Patients to Send Broadcast Message</h6>
            </div>
            <div class="column-stretch-body pad-lr-twenty pad-top-five">
              <div
                v-if="
                  stagedPatients.length == 0 && stagedPrograms.length == 0 && !areAllPatientsStaged
                "
                class="empty-container full-height text-muted"
              >
                <IconPersonAdd class="prime-tasks-empty-icon icon-watermark icon-grey" />
                <h6>To get started, select patients to add.</h6>
              </div>
              <ion-card
                v-else-if="areAllPatientsStaged"
                class="prime-patient-card ion-margin-bottom"
              >
                <ion-row class="ion-align-items-center full-width pad-ten">
                  <ion-col size="auto">
                    <IconPeople class="margin-lr-fifteen icon-medium" />
                  </ion-col>
                  <ion-col>
                    <div class="prime-patient-card-name">
                      All Active Patients
                    </div>
                    <div class="prime-patient-card-info">
                      <span class="text-bold">Patients:</span
                      ><span class="margin-left-five">{{ numberOfPatients.toLocaleString() }}</span>
                    </div>
                  </ion-col>
                  <ion-col size="auto" class="pad-right-ten">
                    <span
                      class="cursor-pointer text-muted close-button"
                      @click="areAllPatientsStaged = false"
                      >&times;</span
                    >
                  </ion-col>
                </ion-row>
              </ion-card>
              <div v-else>
                <div v-if="stagedPatients.length > 0" class="pad-top-five scroll-vert pad-lr-five">
                  <div v-if="duplicateMrnsStagedPatients.length > 0" class="pad-bottom-ten">
                    <ion-card
                      color="warning"
                      v-for="dupMrnPatient in duplicateMrnsStagedPatients"
                      :key="dupMrnPatient.beneficiaryId"
                      class="margin-lr-five"
                    >
                      <ion-row class="ion-align-items-center">
                        <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                          <IconWarning />
                        </ion-col>
                        <ion-col>
                          <ion-row class="text-bold">
                            {{ dupMrnPatient.beneLastName + ", " + dupMrnPatient.beneFirstName }}
                          </ion-row>
                          <ion-row>
                            <span class="text-small">
                              {{
                                dupMrnPatient.duplicateMrn
                                  ? `Duplicate ID: ${dupMrnPatient.duplicateMrn}`
                                  : ""
                              }}
                            </span>
                          </ion-row>
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <ion-card
                    v-for="(patient, key) in stagedPatients"
                    :key="key"
                    class="prime-patient-card ion-margin-bottom"
                  >
                    <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                      {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                    </div>
                    <div>
                      <div class="prime-patient-card-name">
                        {{ patient.beneLastName + ", " + patient.beneFirstName }}
                      </div>
                      <div class="prime-patient-card-info wider-info">
                        <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
                        <div class="display-inline-block" v-if="patient.pcpTitle">
                          <strong>PCP: </strong
                          >{{
                            patient.pcpTitle
                              | formatDisplayName(
                                patient.pcpFirstName,
                                patient.pcpLastName,
                                patient.suffix
                              )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="prime-patient-card-icons">
                      <span
                        class="cursor-pointer text-muted close-button"
                        @click="unstagePatient(patient)"
                        >&times;</span
                      >
                    </div>
                  </ion-card>
                </div>
                <div v-if="stagedPrograms.length > 0" class="pad-top-five scroll-vert pad-lr-five">
                  <ion-card
                    v-for="(program, key) in stagedPrograms"
                    :key="key"
                    class="prime-patient-card ion-margin-bottom pad-top-twenty pad-bottom-twenty"
                  >
                    <IconPrograms slot="start" class="margin-lr-fifteen icon-small" />
                    <div>
                      <div class="prime-patient-card-name">
                        {{ program.name }}
                      </div>
                    </div>
                    <div class="prime-patient-card-icons">
                      <span
                        class="cursor-pointer text-muted close-button"
                        @click="unstageProgram(program)"
                        >&times;</span
                      >
                    </div>
                  </ion-card>
                </div>
                <ion-row>
                  <ion-col></ion-col>
                  <ion-col size="auto">
                    <button
                      @click="
                        stagedPatients = [];
                        stagedPrograms = [];
                      "
                      v-if="stagedPatients.length > 0 || stagedPrograms.length > 0"
                      color="light"
                      class="text-muted prime-button button-secondary"
                    >
                      Clear All
                    </button>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div class="column-stretch-footer pad-twenty">
              <button
                @click="stepNumber = 2"
                :disabled="nextStepEnabled"
                class="prime-button button-primary button-block"
              >
                Next
              </button>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
    <!-- Step Two -->
    <ion-card v-else-if="stepNumber == 2" color="light" class="padding-death split-screen-card">
      <ion-row>
        <ion-col>
          <div class="column-stretch-wrap full-height pad-twenty">
            <div class="column-stretch-header">
              <h6 class="margin-top-zero">Patients to Send Broadcast Message</h6>
            </div>

            <div class="column-stretch-body pad-top-ten">
              <div v-if="areAllPatientsStaged" class="pad-lr-five">
                <ion-card class="prime-patient-card ion-margin-bottom">
                  <ion-row class="ion-align-items-center full-width pad-ten">
                    <ion-col size="auto">
                      <IconPeople class="margin-lr-fifteen icon-medium" />
                    </ion-col>
                    <ion-col>
                      <div class="prime-patient-card-name">
                        All Active Patients
                      </div>
                      <div class="prime-patient-card-info">
                        <span class="text-bold">Patients:</span
                        ><span class="margin-left-five">{{
                          numberOfPatients.toLocaleString()
                        }}</span>
                      </div>
                    </ion-col>
                    <ion-col size="auto" class="pad-right-ten">
                      <span
                        class="cursor-pointer text-muted close-button"
                        @click="
                          areAllPatientsStaged = false;
                          stepNumber = 1;
                        "
                        >&times;</span
                      >
                    </ion-col>
                  </ion-row>
                </ion-card>
              </div>

              <div v-if="stagedPatients.length > 0" class="pad-top-five scroll-vert pad-lr-five">
                <ion-card
                  v-for="(patient, key) in stagedPatients"
                  :key="key"
                  class="prime-patient-card ion-margin-bottom"
                >
                  <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                    {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                  </div>
                  <div>
                    <div class="prime-patient-card-name">
                      {{ patient.beneLastName + ", " + patient.beneFirstName }}
                    </div>
                    <div class="prime-patient-card-info">
                      <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
                      <div class="display-inline-block" v-if="patient.pcpTitle">
                        <strong>PCP: </strong
                        >{{
                          patient.pcpTitle
                            | formatDisplayName(
                              patient.pcpFirstName,
                              patient.pcpLastName,
                              patient.suffix
                            )
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="prime-patient-card-icons">
                    <span
                      class="cursor-pointer text-muted close-button"
                      @click="unstagePatient(patient)"
                      >&times;</span
                    >
                  </div>
                </ion-card>
              </div>
              <div v-if="stagedPrograms.length > 0" class="pad-top-five scroll-vert pad-lr-five">
                <ion-card
                  v-for="(program, key) in stagedPrograms"
                  :key="key"
                  class="prime-patient-card ion-margin-bottom pad-top-twenty pad-bottom-twenty"
                >
                  <IconPrograms slot="start" class="margin-lr-fifteen icon-small" />
                  <div>
                    <div class="prime-patient-card-name">
                      {{ program.name }}
                    </div>
                  </div>
                  <div class="prime-patient-card-icons">
                    <span
                      class="cursor-pointer text-muted close-button"
                      @click="unstageProgram(program)"
                      >&times;</span
                    >
                  </div>
                </ion-card>
              </div>
            </div>
            <div class="column-stretch-footer margin-top-twenty">
              <button @click="stepNumber = 1" class="prime-button button-secondary button-block">
                <ion-icon name="ios-arrow-back" class="icon-back"></ion-icon> Back
              </button>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class="column-stretch-wrap full-height pad-twenty bg-white border-left">
            <div>
              <div class="column-stretch-header">
                <h6 class="margin-top-zero">
                  Message subject (will not be visible to recipients):
                </h6>
              </div>
              <div class="column-stretch-body pad-bottom-twenty pad-top-ten">
                <textarea-autosize
                  maxlength="1000"
                  placeholder="Type subject here..."
                  class="form-control prime-message-input"
                  ref="outboundMessageSubject"
                  rows="3"
                  v-model="outboundSubject"
                  :min-height="40"
                  :max-height="250"
                  @keydown.native.enter.exact.prevent
                  @keydown.native.enter.exact="newline"
                />
              </div>
            </div>
            <div class="column-stretch-header">
              <h6 class="margin-top-zero">Message to broadcast:</h6>
            </div>
            <div class="column-stretch-body pad-bottom-fourty pad-top-ten">
              <textarea
                maxlength="1000"
                placeholder="Type message here..."
                class="form-control prime-message-input full-height"
                ref="outboundMessageInput"
                rows="2"
                v-model="outboundMessage"
                autosize="true"
                @keydown.enter.exact.prevent
                @keydown.enter.exact="newline"
              />
              <ion-col size="auto flex-end">
                <ion-row class="ion-align-items-center">
                  <ion-col></ion-col>
                  <EmojisTool class="icon-grey" v-if="isProfessional" />
                  <PatientEducationTool
                    v-if="
                      isProfessional &&
                        ($can(I.VIEW_HEALTH_EDUCATION) || $can(I.VIEW_SUGGESTED_PRODUCTS))
                    "
                    :beneficiaryId="stagedPatients.length > 0 ? stagedPatients[0].beneficiaryId : 0"
                  />
                  <DbuggaTool v-if="isDbugga" />
                </ion-row>
              </ion-col>
            </div>
            <div class="column-stretch-footer">
              <button
                @click="confirmSendMessage"
                :disabled="!outboundMessage && !isMessageSending"
                class="prime-button button-primary button-block"
                :class="{
                  'button-pending': isMessageSending
                }"
              >
                <span>Send Message</span>
                <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              </button>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </PrimePage>
</template>

<script>
import ErrorNotification from "@/components/Global/ErrorNotification";
import IconPeople from "@/components/Global/Icons/IconPeople";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import IconPrograms from "@/components/Global/Icons/IconPrograms";
import IconWarning from "@/components/Global/Icons/IconWarning";
import PrimePage from "@/components/Global/PrimePage";
import DbuggaTool from "@/components/Tools/DbuggaTool";
import EmojisTool from "@/components/Tools/EmojisTool";
import PatientEducationTool from "@/components/Tools/PatientEducationTool";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import store from "@/store";
import _, { debounce } from "lodash";
import { engageFhirApi } from "../services/EngageFhirApi";

export default {
  name: "BroadcastMessage",
  components: {
    PrimePage,
    ErrorNotification,
    IconPersonAdd,
    IconPeople,
    IconPrograms,
    IconWarning,
    EmojisTool,
    PatientEducationTool,
    DbuggaTool
  },
  data() {
    return {
      allPrograms: [],
      stagedPrograms: [],
      allPatients: [],
      stagedPatients: [],
      duplicateMrnsStagedPatients: [],
      areAllPatientsStaged: false,
      allLoading: false,
      isSearching: false,
      isListLoading: false,
      numberOfPatients: undefined,
      errors: [],
      searchString: undefined,
      programName: undefined,
      patientSearchResults: [],
      addByMode: "patient",
      stepNumber: 1,
      outboundSubject: undefined,
      outboundMessage: undefined,
      isMessageSending: false,
      inputMrns: undefined,
      searchResults: undefined,
      isProcessingMRNs: false,
      broadcastMessageWebappEnabled: false
    };
  },
  watch: {
    searchString: function() {
      this.debounceInput();
    },
    $route() {}
  },
  computed: {
    availablePatients() {
      const t = this;
      return this.allPatients.filter(
        ({ beneficiaryId }) =>
          t.stagedPatients && !t.stagedPatients.some(x => x.beneficiaryId == beneficiaryId)
      );
    },
    availablePrograms() {
      const t = this;
      return this.allPrograms.filter(
        ({ id }) => t.stagedPrograms && !t.stagedPrograms.some(x => x.id == id)
      );
    },
    nextStepEnabled() {
      let nextStep = true;
      if (
        this.stagedPatients.length > 0 ||
        this.stagedPrograms.length > 0 ||
        this.areAllPatientsStaged
      ) {
        return false;
      }
      return nextStep;
    },
    inputMrnsCount() {
      if (!this.inputMrns) {
        return 0;
      }

      this.inputMrns.trim();
      this.inputMrns.replace(/\s+/g, "");
      return this.inputMrns.split(",").length;
    }
  },
  created() {
    this.stagedPatients = store.getters["patients/patients"];
    EventBus.$on("addToMessageInput", this.handleNewInput);

    this.broadcastMessageWebappEnabled = store.getters["appConfig/broadcastMessageWebappEnabled"];
  },
  mounted() {
    if (this.$route.params.mode === "bulk") {
      this.searchResults = undefined;
      this.addByMode = this.$route.params.mode;
      let mrns = JSON.stringify(this.$route.params.bulkedMrns);
      this.inputMrns = mrns.substring(1, mrns.length - 1);
      this.processMrns();
    }
    this.loadPatients(false);

    this.duplicateMrnsStagedPatients = this.getDuplicateMrnPatients(this.stagedPatients);
  },
  deactivated() {
    this.errors = [];
  },
  methods: {
    handleNewInput(input) {
      this.outboundMessage += input;
    },
    debounceInput: _.debounce(function() {
      if (this.searchString && this.searchString.length >= 1) {
        this.notifications = [];
        this.isSearching = true;
      }
      this.searchPatients();
    }, 400),
    async loadPatients(morePatients) {
      this.allLoading = true;
      morePatients ? (this.isLoadingMorePatients = true) : (this.isLoadingPatients = true);

      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;

      var payload = {
        page: this.pageNumber || 1,
        perPage: this.perPage,
        columnFilters: {
          beneLastName: this.searchString,
          beneFirstName: this.searchString,
          mrn: this.searchString,
          dob: this.searchString,
          active: "true",
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          if (result.data.results.length < this.perPage) {
            this.allPatients = [...this.allPatients, ...result.data.results];
          } else {
            this.allPatients = [...this.allPatients, ...result.data.results];
          }
          this.numberOfPatients = result.data.totalRecords;
          this.isLoadingPatients = false;
          this.isLoadingMorePatients = false;
          this.pageIsLoading = false;
        })
        .catch(error => {
          this.isLoadingPatients = false;
          this.isLoadingMorePatients = false;
          khanSolo.log(error);
        })
        .finally(() => {
          this.allLoading = false;
        });
    },

    async searchPatients() {
      this.isListLoading = true;
      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;

      var payload = {
        page: "1",
        perPage: "20",
        columnFilters: {
          beneLastName: this.searchString,
          beneFirstName: this.searchString,
          mrn: this.searchString,
          dob: this.searchString,
          active: "true",
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.allPatients = result.data.results;
        })
        .catch(error => {
          khanSolo.log(error);
        })
        .finally(() => {
          this.isListLoading = false;
        });
    },
    getPrograms() {
      const method = "get";
      const path = document.config.programsApi;
      this.isListLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            // remove the text triage program
            rtnArr = rtnArr.filter(x => x.programCategoryName != "Unspecified");

            rtnArr.forEach(progCat =>
              progCat.programs.forEach(prog => this.allPrograms.push(prog))
            );
          }
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to load programs",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isListLoading = false;
        });
    },
    changeAddByMode(evt) {
      if (evt.target.value == "program") {
        this.getPrograms();
      }
      this.addByMode = evt.target.value;
    },

    stagePatient: function(patient) {
      this.stagedPatients.push(patient);
      this.duplicateMrnsStagedPatients = this.getDuplicateMrnPatients(this.stagedPatients);
    },

    stageProgram: function(program) {
      this.stagedPrograms.push(program);
    },

    unstagePatient: function(patient) {
      const index = this.stagedPatients.map(x => x.beneficiaryId).indexOf(patient.beneficiaryId);
      this.stagedPatients.splice(index, 1);

      // check for duplicate Id after removing patient
      const foundPatients = this.stagedPatients.filter(p => patient.mrn && p.mrn === patient.mrn);

      if (foundPatients && foundPatients.length <= 1) {
        for (const foundPatient of foundPatients) {
          foundPatient.duplicateMrn = undefined;
        }
      }
      patient.duplicateMrn = undefined;

      this.duplicateMrnsStagedPatients = this.getDuplicateMrnPatients(this.stagedPatients);

      this.stagedPatients.length < 1 && this.stagedPrograms.length < 1 ? (this.stepNumber = 1) : "";
    },

    unstageProgram: function(program) {
      const index = this.stagedPrograms.map(x => x.beneficiaryId).indexOf(program.beneficiaryId);
      this.stagedPrograms.splice(index, 1);
      this.stagedPatients.length < 1 && this.stagedPrograms.length < 1 ? (this.stepNumber = 1) : "";
    },
    stageAllPatients() {
      // reset staged programs and patients
      this.stagedPatients = [];
      this.stagedPrograms = [];
      this.areAllPatientsStaged = true;
    },
    searchAllAvailablePatients: debounce(
      async function() {
        this.loadPatients(false);
      },
      1000,
      { leading: true, trailing: false }
    ),
    confirmSendMessage() {
      this.$ionic.popoverController.dismiss();

      this.$ionic.alertController
        .create({
          header: this.areAllPatientsStaged
            ? "Confirm Send Broadcast Message"
            : "Send Broadcast Message?",
          message: this.areAllPatientsStaged
            ? "Are you sure you want to send this message to <strong>" +
              this.numberOfPatients.toLocaleString() +
              " </strong>patients?"
            : "Please confirm that you would like to send your message to all patients and programs selected.",
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Send Message",
              handler: () => {
                this.sendMessage();
              }
            }
          ]
        })
        .then(a => a.present());
    },
    async sendMessage() {
      this.isMessageSending = true;
      const claims = await this.$authState.claims();
      const path = document.config.workRequest;
      const method = "POST";
      let payload = {
        beneficiary_ids: [],
        program_ids: [],
        professional_id: claims.professionalId,
        subject: this.outboundSubject,
        message_text: this.outboundMessage
      };

      if (this.stagedPatients.length > 0) {
        this.stagedPatients.forEach(el => {
          payload.beneficiary_ids.push(el.beneficiaryId);
        });
      }

      if (this.stagedPrograms.length > 0) {
        this.stagedPrograms.forEach(el => payload.program_ids.push(el.id));
      }

      try {
        if (this.broadcastMessageWebappEnabled) {
          payload.recipients = [];

          if (this.stagedPatients.length > 0) {
            this.stagedPatients.forEach(el => {
              payload.recipients.push({
                patientId: el.beneficiaryId,
                accountId: el.accountId
              });
            });
          }

          khanSolo.log("Sending broadcast message via webapp");
          await engageFhirApi.post("broadcast", payload);
        } else {
          await httpSend({ path, method, authToken: true, payload });
        }
        this.isLoading = false;

        this.$ionic.toastController
          .create({
            header: "Success",
            message: "Your message was scheduled to be sent",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());

        this.stagedPatients = [];
        this.stagedPrograms = [];
        this.isMessageSending = false;
        this.stepNumber = 1;
        this.areAllPatientsStaged = false;
        this.outboundMessage = undefined;
        this.addByMode = "patient";
        this.resetState();
      } catch (err) {
        this.isLoading = false;
        this.isMessageSending = false;
        khanSolo.log(err);
        this.errors.push(err.response.data.message);
      }
    },
    newline() {
      this.outboundMessage = `${this.outboundMessage}\n`;
    },
    resetState() {
      this.searchResults = undefined;
      this.inputMrns = undefined;
      this.selectedEvent = undefined;
      this.outputAccountIds = [];
      this.outputBeneIds = [];
      this.stagedPatients = [];
      this.stagedPrograms = [];
      this.stepNumber = 1;
    },
    cleanUpMrns(mrns) {
      return mrns
        .trim()
        .split("\n")
        .join(",")
        .replaceAll("'", "")
        .replaceAll('"', "")
        .replaceAll(/\s+/g, "");
    },
    processMrns() {
      this.searchResults = undefined;
      this.isProcessingMRNs = true;
      const method = "post";
      const path = document.config.mrnSearchApi;

      this.inputMrns = this.cleanUpMrns(this.inputMrns);

      const payload = {
        page: 1,
        perPage: this.inputMrnsCount * 10, // TODO handle paging to get all the results
        mrn: this.inputMrns,
        setMissingRecordsArrayCount: 10
      };

      httpSend({ path, method, payload, authToken: true })
        .then(result => {
          this.searchResults = result.data;

          this.duplicateMrnsStagedPatients = this.getDuplicateMrnPatients(
            this.searchResults && this.searchResults.results
          );
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Processing MRNs failed. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isProcessingMRNs = false;
        });
    },
    addPatientsByMrns() {
      this.stagedPatients = this.searchResults.results;
    },
    downloadReport(url) {
      this.isGettingRecentConvos = true;
      const method = "get";
      const path = document.config.baseApiUrl + url;

      httpSend({ path, method, authToken: true })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "MyPCP-ID-Search-Report.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not download report. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
          this.isGettingRecentConvos = false;
        });
    },
    goToNamedRoute(routeName) {
      if (this.$route.query.backId) {
        this.$router.push({
          name: routeName,
          params: { id: this.$route.query.backId }
        });
      } else {
        this.$router.push({
          name: routeName
        });
      }
    },

    getDuplicateMrnPatients(patients) {
      if (!patients || patients.length < 1) {
        return [];
      }

      const duplicateMrns = patients.reduce((a, e) => {
        a[e.mrn] = ++a[e.mrn] || 0;
        return a;
      }, {});

      patients.forEach(patient => {
        if (duplicateMrns[patient.mrn] > 0) {
          patient.duplicateMrn = patient.mrn;
        }
      });

      return patients.filter(p => p.duplicateMrn);
    }
  }
};
</script>

<style scoped>
form h5 {
  margin-top: 40px;
}

.city {
  display: inline-block;
  width: 73%;
}

.state {
  display: inline-block;
  width: 25%;
  margin-left: 2%;
}

.send-email {
  font-size: 11px;
  font-weight: 700;
  margin-top: 3px;
  color: var(--ion-color-primary);
}

.send-email.disabled {
  color: var(--ion-color-medium);
}
/* avatar styles */
/* ion-card.prime-patient-card .avatar {
  width: 40px;
  height: 40px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
} */

/* ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
  min-height: 90px;
}

ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
}

ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
}

ion-card.prime-patient-card .prime-patient-card-name,
ion-card.prime-patient-card .prime-patient-card-info {
  width: 100%;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-card.prime-patient-card .prime-patient-card-info.wider-info {
  max-width: 330px;
}

ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
} */
.close-button {
  font-size: 30px;
  border-radius: 20px;
  color: #a0a0a0;
  padding: 1px;
}

.add-patient-select-container {
  display: flex;
  flex-direction: column;
}

.scroll-vert {
  overflow-y: auto;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
.border-left {
  border-left: 2px solid #dadada;
}
.icon-watermark {
  width: 90px;
  fill: #c9ccd2;
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

ion-card.split-screen-card {
  width: calc(100% - 60px);
  min-width: 800px;
  max-width: 1100px;
  align-self: center;
  flex-grow: 2;
}

.split-screen-card > ion-row,
.split-screen-card > ion-row > ion-col {
  height: 100%;
}

.mrn-textarea {
  height: 98% !important;
  margin-bottom: 10px;
}

.m-r {
  margin-right: 15px;
}

.mrn-holder {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}
</style>
