<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar class="bottom-divider-v2" v-if="!isCarded">
        <h5 slot="start">
          Quality Consideration - Clinical Data Entry Review
        </h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal()"
          expand="full"
          color="medium"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
      <ion-toolbar class="bottom-divider-v2">
        <ion-row>
          <ion-col>
            <div class="text-muted text-small margin-bottom-five">
              Quality Consideration
            </div>
            <div>
              <h6>{{ clinicalFormData.qualityConsideration }}</h6>
            </div>
          </ion-col>
          <ion-col v-if="formData.createdByProfessionalName">
            <div class="text-muted text-small margin-bottom-five">
              <span class=" text-capitalize">{{ getCdeStatus(item.cdeStatus) }}: </span>
              Clinical Data Entered By
            </div>
            <div>
              <span class="text-bold">{{ formData.createdByProfessionalName }}</span>
              <span class="text-muted">
                - {{ formData.createdAt | moment("MM/DD/YYYY - h:mmA") }}
              </span>
            </div>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="full-height">
        <ion-row class="full-height">
          <ion-col class="tabs-divider-no-margin pad-top-twenty pad-lr-ten">
            <div class="text-bold margin-left-ten">Satisfying Evidence</div>
            <ul class="evidence-cards">
              <li
                v-if="!value.exclusion"
                v-for="(value, key) in clinicalFormData.entries"
                :key="key"
                :class="activatedItem == key ? 'activated cursor-pointer' : 'cursor-pointer'"
                @click="activateCategory(key)"
              >
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto">
                    <ion-checkbox
                      disabled="true"
                      class="discheck"
                      color="primary"
                      :checked="getSubChecked(payload[key]) || selectedFields.indexOf(key) > -1"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="11" class="margin-left-ten cursor-pointer"
                    ><div class="display-inline-block">{{ value.displayName }}</div>
                    <IconCarrot />
                  </ion-col>
                </ion-row>
              </li>
            </ul>
            <div class="text-bold margin-left-ten">Excluding Evidence</div>
            <ul class="evidence-cards">
              <li
                v-if="value.exclusion"
                v-for="(value, key) in clinicalFormData.entries"
                :key="key"
                :class="activatedItem == key ? 'activated cursor-pointer' : 'cursor-pointer'"
                @click="activateCategory(key)"
              >
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto">
                    <ion-checkbox
                      class="discheck"
                      disabled="true"
                      color="primary"
                      :checked="getSubChecked(payload[key]) || selectedFields.indexOf(key) > -1"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="11" class="margin-left-ten"
                    ><div class="display-inline-block">{{ value.displayName }}</div>
                    <IconCarrot />
                  </ion-col>
                </ion-row>
              </li>
            </ul>
          </ion-col>
          <ion-col :key="activatedItem" class="pad-top-twenty pad-left-ten">
            <div v-if="errorMsgs.length" class="danger-well">
              <ul>
                <li v-for="(msg, index) in errorMsgs" :key="index" v-html="msg"></li>
              </ul>
            </div>
            <div :class="{ invisible: activatedItem === '' }">
              <div class="text-bold text-capitalize" v-if="activatedItem">
                {{ clinicalFormData.entries[activatedItem].displayName }}
              </div>

              <ul v-if="activatedItem" class="reset-list">
                <li
                  v-for="(value, key) in clinicalFormData.entries[activatedItem].detailedEvidence"
                  :key="key"
                >
                  <ion-item v-if="value.inputType === 'checkbox'" class="prime-item">
                    <ion-checkbox
                      :disabled="readOnlyForm"
                      :id="key"
                      :key="!!+payload[activatedItem][key].value"
                      :checked="!!payload[activatedItem][key].value"
                      color="primary"
                      @click="
                        payload[activatedItem][key].value = !payload[activatedItem][key].value;
                        selectedPayloadFields(payload[activatedItem][key].value);
                      "
                    ></ion-checkbox>
                    <label :for="key">{{ value.displayName }}</label>
                  </ion-item>
                  <ion-row v-if="value.inputType === 'numberInput'">
                    <ion-col>
                      <NumberInputType
                        :disabled="readOnlyForm"
                        :label="value.displayName"
                        :value="Number(payload[activatedItem][key].value)"
                        @change="val => changeVal(Number(val), key)"
                        @input="val => changeVal(Number(val), key)"
                        :unit="value.unitOfMeasure"
                        :min="value.validations.min"
                        :max="value.validations.max"
                        :step="10"
                        :description="
                          `min: ${value.validations.min}, max: ${value.validations.max}`
                        "
                      />
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="value.inputType === 'singleSelect'">
                    <ion-col>
                      {{ value.displayName }}
                      <ion-item
                        class="select-options"
                        v-for="(val, index) of value.options"
                        :key="index"
                        @click="changeVal(val, key)"
                      >
                        <ion-checkbox
                          :disabled="!isSubmitable && !isReviewable"
                          :id="val.value"
                          :checked="val.value === payload[activatedItem][key].value"
                        />
                        <label :for="val.value">{{ val.displayName }}</label>
                      </ion-item>
                    </ion-col>
                  </ion-row>

                  <ion-row v-if="value.serviceProviderNpiRequired">
                    <ion-col>
                      <NumberInputType
                        :disabled="readOnlyForm"
                        :label="'Provider NPI'"
                        :value="Number(payload[activatedItem][key].serviceProviderNpi)"
                        @change="val => setProviderNpi(val, key)"
                        @input="val => setProviderNpi(val, key)"
                      />
                    </ion-col>
                  </ion-row>

                  <ion-row v-if="value.serviceProviderTypeValues">
                    <ion-col>
                      <label class="select-label">Provider Type</label>
                      <select class="form-control" @change="val => setProviderType(val, key)">
                        <option :value="undefined">Select...</option>
                        <option
                          v-for="option in value.serviceProviderTypeValues.map(
                            x => x && x.split(',')
                          )"
                          :key="option && option.length == 2 && option[1].trim()"
                          :value="option && option.length == 2 && option[1].trim()"
                          :selected="
                            option &&
                              option.length == 2 &&
                              option[1].trim() ===
                                (payload[activatedItem][key].serviceProviderTypeCode &&
                                  payload[activatedItem][key].serviceProviderTypeCode.trim())
                          "
                          >{{ option && option.join(",").trim() }}
                        </option>
                      </select>
                    </ion-col>
                  </ion-row>
                </li>
              </ul>

              <ul v-if="activatedItem" class="reset-list">
                <div v-if="!isSubmitable">
                  <li v-for="(fileItem, index) in payload[activatedItem].attachments" :key="index">
                    <ion-row class="pad-top-ten">
                      Date of service
                    </ion-row>
                    <ion-row class="ion-align-items-center">
                      <ion-col size="4" class="margin-right-twenty cursor-pointer">
                        <div class="prime-form-block-input">
                          <div class="prime-v-date-picker-container date-picker-height">
                            <div v-if="isReviewable || isSubmitable">
                              <input
                                :id="fileItem.dateOfService + '-ds'"
                                :value="getDateFunc(fileItem.dateOfService) | moment('MM/DD/YYYY')"
                                class="form-control"
                                inputmode="numeric"
                                @blur="
                                  updateDateValue(
                                    fileItem.attachmentUrl,
                                    getDateFunc($event.target.value),
                                    'dayEntered',
                                    index
                                  )
                                "
                                type="text"
                                v-mask="{
                                  mask: ['99/99/9999'],
                                  placeholder: 'MM/DD/YYYY',
                                  clearMaskOnLostFocus: false
                                }"
                              />
                            </div>
                            <span class="prime-v-date-picker" v-else>
                              <input
                                disabled
                                :id="fileItem.dateOfService + '-ds'"
                                :value="fileItem.dateOfService"
                                class="form-control medium-input disabled"
                                inputmode="numeric"
                                type="text"
                                v-mask="{
                                  mask: ['99/99/9999'],
                                  placeholder: 'MM/DD/YYYY',
                                  clearMaskOnLostFocus: false
                                }"
                              />
                            </span>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col
                        v-if="
                          fileItem.attachmentFilename &&
                            filesToUpload &&
                            (!filesToUpload[activatedItem][index] ||
                              !filesToUpload[activatedItem][index].attachmentFilename)
                        "
                        size="7"
                      >
                        <div class="file-card card-like">
                          <div><IconPaper class="icon-small margin-right-five" /></div>
                          <div
                            class="text-bold file-name cursor-pointer"
                            @click="downloadFile(fileItem.attachmentUrl)"
                          >
                            {{ fileItem.attachmentFilename }}
                          </div>
                          <div
                            v-if="$can(I.MODIFY_CDE_REQUEST) && isSubmitable"
                            class="cursor-pointer"
                            @click="removeFile(fileItem.attachmentUrl)"
                          >
                            <IconDeleteTrash slot="end" class="icon-small icon-muted" />
                          </div>
                        </div>
                      </ion-col>
                      <ion-col v-else size="7">
                        <div
                          v-if="isSubmitable"
                          class="file-card drag-and-drop text-bold"
                          :class="{ dropping: isDropping }"
                          @drop.prevent="dropHandler"
                          @dragover.prevent="isDropping = true"
                          @dragleave.prevent="isDropping = false"
                          @dragend.prevent="isDropping = false"
                        >
                          <input
                            type="file"
                            name="file"
                            id="newFile"
                            class="prime-file-upload"
                            @change="
                              fileOrderIndex = index;
                              fileSelected($event);
                            "
                          />
                          <div style="flex: 1; max-width: 40px;">
                            <IconUpload class="icon-small" />
                          </div>
                          <div
                            v-if="
                              !filesToUpload[activatedItem][index] ||
                                !filesToUpload[activatedItem][index].attachmentFilename
                            "
                            style="flex: 9;"
                            class="file-name"
                          >
                            <label for="file" class="file-upload-sect">
                              <div class="text-bold">
                                Upload supporting evidence
                                {{
                                  !clinicalFormData.entries[activatedItem].supportingFiles
                                    .required ||
                                  (payload[activatedItem].attachments.length && index !== 0)
                                    ? "(optional)"
                                    : ""
                                }}
                              </div>
                              <span class="text-small">
                                Drag and drop here
                              </span>
                            </label>
                          </div>
                          <div style="flex: 5;" v-else>
                            <div class="display-flex">
                              <label class="file-upload-sect upload-2">
                                <!-- D1* -->
                                {{
                                  payload[activatedItem].attachments[index].attachmentFilename ||
                                    filesToUpload[activatedItem][index].attachmentFilename
                                }}
                              </label>
                              <div
                                v-if="$can(I.MODIFY_CDE_REQUEST) && isSubmitable"
                                class="cursor-pointer"
                                style="z-index: 1000;"
                                @click="
                                  removeFile(
                                    filesToUpload[activatedItem][index].attachmentUrl,
                                    'newFile',
                                    index
                                  )
                                "
                              >
                                <IconDeleteTrash slot="end" class="icon-small icon-muted" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </li>
                </div>

                <li
                  v-if="
                    isSubmitable ||
                      (isReviewable && !payload[activatedItem].attachments[fileOrderIndex])
                  "
                >
                  <ion-row class="pad-top-ten">
                    Date of service
                  </ion-row>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="4" class="margin-right-twenty cursor-pointer">
                      <div class="prime-form-block-input">
                        <div class="prime-v-date-picker-container date-picker-height">
                          <div v-if="!readOnlyForm" class="">
                            <input
                              :value="
                                filesToUpload &&
                                filesToUpload[activatedItem] &&
                                filesToUpload[activatedItem][fileOrderIndex]
                                  ? filesToUpload[activatedItem][fileOrderIndex].dateOfService
                                  : null | moment('MM/DD/YYYY')
                              "
                              class="form-control"
                              inputmode="numeric"
                              :id="`dateInput-${payload[activatedItem].attachments.length}`"
                              @blur="
                                fileOrderIndex = 0;
                                updateDateValue('newFile', $event);
                              "
                              type="text"
                              v-mask="{
                                mask: ['99/99/9999'],
                                placeholder: 'MM/DD/YYYY',
                                clearMaskOnLostFocus: false
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </ion-col>
                    <ion-col size="7">
                      <div
                        class="file-card drag-and-drop text-small"
                        :class="{ dropping: isDropping }"
                        @drop.prevent="dropHandler"
                        @dragover.prevent="isDropping = true"
                        @dragleave.prevent="isDropping = false"
                        @dragend.prevent="isDropping = false"
                      >
                        <input
                          type="file"
                          name="file"
                          id="newFile-additional"
                          class="prime-file-upload cursor-pointer"
                          @change="fileSelected($event)"
                        />
                        <div v-if="!isUploading" style="flex: 1; max-width: 40px;">
                          <IconUpload class="icon-small" />
                        </div>
                        <div
                          v-if="
                            filesToUpload && filesToUpload[activatedItem]
                              ? filesToUpload[activatedItem] &&
                                filesToUpload[activatedItem][fileOrderIndex] &&
                                filesToUpload[activatedItem][fileOrderIndex].attachmentUrl
                              : null
                          "
                          style="flex: 5;"
                        >
                          <div v-if="isUploading" class="text-center">
                            <ion-spinner name="lines" class="spinner-small"></ion-spinner>
                          </div>
                          <div v-else class="display-flex">
                            <label class="file-upload-sect upload-2 text-bold">
                              {{
                                filesToUpload && filesToUpload[activatedItem]
                                  ? filesToUpload[activatedItem] &&
                                    filesToUpload[activatedItem][fileOrderIndex] &&
                                    filesToUpload[activatedItem][fileOrderIndex].attachmentFilename
                                  : "loading..."
                              }}
                            </label>
                            <div
                              v-if="!readOnlyForm"
                              class="cursor-pointer"
                              style="z-index: 1000;"
                              @click="
                                removeFile(
                                  filesToUpload[activatedItem][fileOrderIndex].attachmentUrl,
                                  'newFile-additional',
                                  fileOrderIndex
                                )
                              "
                            >
                              <IconDeleteTrash slot="end" class="icon-small icon-muted" />
                            </div>
                          </div>
                        </div>
                        <div v-else style="flex: 9;" class="file-name">
                          <label for="file" class="file-upload-sect">
                            <div class="text-bold">Upload supporting evidence</div>
                            <span class="text-small">
                              Drag and drop here
                            </span>
                          </label>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                </li>
              </ul>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row v-if="isReviewable">
          <ion-col></ion-col>
          <ion-col class="pad-right-ten full-height overflow-y-scroll">
            <button @click="closeModal" class="prime-button button-mid-large button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col v-if="canReject" class="pad-right-ten">
            <button
              v-if="isReviewEnabled"
              class="btn-mid reject-button"
              @click="initialSubmit('reject')"
              :disabled="isSaveEnable"
            >
              <IconReject slot="start" class="icon-small margin-right-five" />
              <span>Reject</span>
            </button>
          </ion-col>
          <ion-col v-if="payloadChanged">
            <button
              @click="initialSubmit('update')"
              class="prime-button btn-mid"
              :disabled="isSaveEnable"
            >
              <span>
                <IconEditPencil class="icon-small margin-right-five" />
                Update and Resubmit
              </span>
            </button>
          </ion-col>
          <ion-col v-else-if="canApprove">
            <button
              v-if="isReviewEnabled"
              @click="approve()"
              class="btn-mid approve-button"
              :disabled="isSaveEnable"
            >
              <span>
                <IconApprove class="icon-small margin-right-five" />
                Approve
              </span>
            </button>
          </ion-col>
        </ion-row>

        <ion-row v-else
          ><ion-col></ion-col>
          <ion-col></ion-col>
          <ion-col
            v-if="!isCarded || !isReviewable"
            :key="!isReviewable"
            class="pad-right-ten full-height"
          >
            <button @click="closeModal()" class="prime-button full-width button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col v-if="isReadyForEntry && isSubmitable" class="pad-right-ten">
            <button class="prime-button full-width button-primary submitter" @click="initialSubmit">
              <span>Submit</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import IconApprove from "@/components/Global/Icons/IconApprove";
import IconCarrot from "@/components/Global/Icons/IconCarrot.vue";
import IconDeleteTrash from "@/components/Global/Icons/IconDeleteTrash";
import IconEditPencil from "@/components/Global/Icons/IconEditPencil";
import IconPaper from "@/components/Global/Icons/IconPaper";
import IconReject from "@/components/Global/Icons/IconReject";
import IconUpload from "@/components/Global/Icons/IconUpload";
import NumberInputType from "@/components/Global/NumberInputType.vue";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import fileSizeInRange from "@/utils/FormHelpers/fileSizeInRange";
import { can, I } from "@/utils/permissions";
import { isEqual, pick, uniqWith } from "lodash";
import moment from "moment";
import ModalUpdateGapEntry from "./ModalUpdateGapEntry.vue";

export default {
  name: "ModalClinicalData",
  components: {
    IconCarrot,
    IconDeleteTrash,
    IconPaper,
    IconApprove,
    IconReject,
    IconUpload,
    IconEditPencil,
    NumberInputType
  },
  props: {
    beneId: Number,
    item: Object,
    isCarded: Boolean
  },
  data() {
    return {
      isUploading: false,
      error: undefined,
      warnings: undefined,
      fileToUpload: undefined, // this should not be used
      showUploadBtn: false,
      isDropping: false,
      activatedItem: "",
      evidenceType: "",
      clinicalFormData: {},
      payload: {},
      originalPayload: {},
      payloadChanged: false,
      fileUploaded: {}, // this should not be used
      selectedFields: [],
      formData: {},
      readOnlyForm: false,
      isRejected: false,
      errorMsgs: [],
      editMode: false,
      fileOrderIndex: 0,
      filesToUpload: {},
      subset: {},
      isUpdated: false,
      inputValue: "", // this should not be used
      invalidDate: false,
      isReviewable: null,
      isSubmitable: null,
      canApprove: null,
      canReject: null,
      attachments: [] // this should not be used
    };
  },
  async beforeMount() {
    this.readOnlyForm =
      ["approved", "rejected"].includes(this.item.cdeStatus) || !can(I.ADD_CDE_REQUEST);
    this.editMode =
      [
        "first_review_needed",
        "second_review_needed",
        "in_first_review",
        "in_second_review"
      ].includes(this.item.cdeStatus) &&
      (can(I.REVIEW_CDE_REQUEST) ||
        can(I.REVIEW_CDE_REQUEST_FIRST) ||
        can(I.REVIEW_CDE_REQUEST_SECOND));

    EventBus.$on("statusChanged", () => {
      EventBus.$emit("QCFormSubmitted", this.item.cdeFormId);
      EventBus.$emit("updatingGap");
      this.isUpdated = true;
      this.$destroy();
      this.closeModal();
    });
    if (this.editMode) {
      const method = "put";
      const path = document.config.putCdeRequest + this.item.cdeRequestId + `/in-review`;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "In Review",
              message: "",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.editMode = false;
          this.readOnlyForm = true;
          this.$ionic.toastController
            .create({
              header: "View Only Mode",
              message: error.response.data.message,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  },
  destroyed() {
    if (this.editMode && !this.isUpdated) {
      const method = "put";
      const path = document.config.putCdeRequest + this.item.cdeRequestId + `/clear`;

      httpSend({ path, method, authToken: true })
        .then(() => {})
        .catch(error => {
          khanSolo.log(error);
        });
    }
    EventBus.$off("statusChanged");
  },
  watch: {
    payload: {
      handler: function() {
        if (!isEqual(this.payload[this.activatedItem], this.originalPayload[this.activatedItem])) {
          this.payloadChanged = true;
        } else {
          this.payloadChanged = false;
        }
      },
      deep: true
    }
  },
  async mounted() {
    const isCdeAdmin = can(I.REVIEW_CDE_REQUEST);
    const isFirstReviewAllowed =
      ["first_review_needed"].includes(this.item.cdeStatus) && can(I.REVIEW_CDE_REQUEST_FIRST);
    const isSecondReviewAllowed =
      ["second_review_needed"].includes(this.item.cdeStatus) && can(I.REVIEW_CDE_REQUEST_SECOND);

    this.canApprove =
      (isCdeAdmin && ["rejected"].includes(this.item.cdeStatus)) ||
      (!["approved", "ready_for_entry"].includes(this.item.cdeStatus) &&
        (isCdeAdmin || isFirstReviewAllowed || isSecondReviewAllowed));
    this.canReject =
      !["approved", "rejected", "ready_for_entry"].includes(this.item.cdeStatus) &&
      (isCdeAdmin || isFirstReviewAllowed || isSecondReviewAllowed);

    this.isReviewable = this.canApprove || this.canReject;

    this.isSubmitable = ["ready_for_entry"].includes(this.item.cdeStatus) && can(I.ADD_CDE_REQUEST);

    if (this.item.cdeRequestId) {
      const method = "get";
      const path = document.config.getCdeRequest + this.item.cdeRequestId;
      const response = await httpSend({ path, method, authToken: true });
      this.formData = await response.data;
    }

    const method = "get";
    const path = document.config.cdeForm + this.item.cdeFormId;

    httpSend({ path, method, authToken: true })
      .then(response => {
        this.clinicalFormData = response.data.form;
        let data = response.data.form;
        for (let key in data.entries) {
          let detailedEntries = {};
          for (let k in data.entries[key].detailedEvidence) {
            let initialValue =
              data.entries[key].detailedEvidence[k]["inputType"] === "checkbox" ? false : 0;
            detailedEntries = {
              ...detailedEntries,
              [k]: { metadata: data.entries[key].detailedEvidence[k].metadata, value: initialValue }
            };
          }
          this.payload = {
            ...this.payload,
            [key]: { exclusion: data.entries[key].exclusion, ...detailedEntries, attachments: [] }
          };
        }

        if (this.formData.data) {
          this.payload = { ...this.payload, ...this.formData.data.entries };
          this.selectedFields = Object.keys(this.formData.data.entries);
        }

        this.attachments =
          this.payload[this.selectedFields] && this.payload[this.selectedFields].attachments
            ? this.payload[this.selectedFields].attachments
            : null;
        // eslint-disable-next-line no-undef
        this.originalPayload = structuredClone(this.payload);
      })
      .catch(error => {
        this.$ionic.toastController
          .create({
            header: "Failed to load data",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      })
      .finally(() => {
        this.isTableLoading = false;
      });
  },
  methods: {
    activateCategory(key) {
      if (this.isUploading) {
        return;
      }

      this.activatedItem = key;
      if (!(key in this.filesToUpload)) {
        this.filesToUpload[key] = {};
      }
    },
    getDateFunc(d) {
      return d ? new Date(moment(d)) : "";
    },
    triggerCalendar(e, rowType) {
      if (!rowType) {
        setTimeout(() => {
          const cal = document.getElementById(e);
          //cal.click();
          cal.focus();
        }, 400);
      } else {
        setTimeout(() => {
          const cal = document.getElementById(e);
          cal.click();
        }, 100);
      }
    },
    removeFile(fileUrl, fileState, rowIndex) {
      this.payload[this.activatedItem].attachments = this.payload[
        this.activatedItem
      ].attachments.filter(fileItem => fileItem.attachmentUrl !== fileUrl);
      delete this.filesToUpload[this.activatedItem][rowIndex];
    },
    selectedPayloadFields(val) {
      if (val) {
        if (this.selectedFields.indexOf(this.activatedItem) < 0)
          this.selectedFields.push(this.activatedItem);
      } else {
        this.selectedFields.splice(this.activatedItem);
      }
    },
    changeVal(v, k) {
      if (typeof v === "object") {
        let valueObj = { value: v.value, metadata: v.metadata };
        this.payload[this.activatedItem][k] = valueObj;
        this.selectedPayloadFields(this.payload[this.activatedItem][k]);
      } else {
        this.payload[this.activatedItem][k].value = v;
        this.selectedPayloadFields(this.payload[this.activatedItem][k].value);
      }
    },
    setProviderNpi(val, key) {
      this.payload[this.activatedItem][key].serviceProviderNpi = val;
    },
    setProviderType(val, key) {
      this.payload[this.activatedItem][key].serviceProviderTypeCode =
        val && val.target && val.target.value;
    },
    async downloadFile(fileUrl) {
      const method = "post";
      const path = document.config.getCdeAttachmentSignedUrl;
      const payload = {
        url: fileUrl
      };

      const response = await httpSend({ path, payload, method, authToken: true });
      const downloadUrl = response.data.url;
      const downloadUrl_method = "get";
      const tfile = response.data.filename;
      const responseType = "blob";
      const headers = {
        "Cache-Control": "no-cache"
      };

      httpSend({
        path: downloadUrl,
        method: downloadUrl_method,
        headers,
        authToken: false,
        responseType
      })
        .then(respo => {
          // force file download
          const url = window.URL.createObjectURL(new Blob([respo.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", tfile);
          document.body.appendChild(link);
          link.click();
          // end force file download
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to download file properly. Please try again later.",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
          this.isDownloading = false;
        });
    },
    updateDateValue(fItem, dateVal, triggerType, idx) {
      if (fItem !== "newFile") {
        if (fItem && moment(dateVal)._d != "Invalid Date") {
          this.invalidDate = false;
          let fItemObjIndex = this.payload[this.activatedItem].attachments.findIndex(
            file => file.attachmentUrl === fItem
          );
          this.$set(
            this.payload[this.activatedItem].attachments[fItemObjIndex],
            "dateOfService",
            moment(dateVal).format("L")
          );
        } else {
          this.invalidDate = true;
          this.$set(
            this.payload[this.activatedItem].attachments[idx],
            "dateOfService",
            moment(dateVal).format("L")
          );
          if (moment(dateVal)._d != "Invalid Date") {
            this.invalidDate = false;
            this.$set(
              this.payload[this.activatedItem].attachments[idx],
              "dateOfService",
              moment(dateVal).format("L")
            );
          }
        }
      } else {
        if ((dateVal.target && dateVal.target.value) || dateVal instanceof Date) {
          if (moment(dateVal.target.value)._d != "Invalid Date") {
            const dValue =
              dateVal instanceof Date ? dateVal : moment(dateVal.target.value).format("L");

            this.inputValue = dValue;
            this.invalidDate = false;

            if (
              this.filesToUpload[this.activatedItem][this.fileOrderIndex] &&
              this.filesToUpload[this.activatedItem][this.fileOrderIndex].attachmentFilename
            ) {
              this.filesToUpload[this.activatedItem][this.fileOrderIndex].dateOfService = dValue;
            } else {
              this.filesToUpload[this.activatedItem][this.fileOrderIndex] = {
                dateOfService: dValue
              };
            }
          }
        } else {
          setTimeout(() => {
            const dValue =
              dateVal.target && dateVal.target.value && dateVal.target.value !== ""
                ? dateVal.target.value
                : new Date(moment(dateVal));

            if (dValue == "Invalid Date" || triggerType == "dayClicked") {
              return;
            }

            if (dValue != "Invalid Date") {
              if (
                this.filesToUpload[this.activatedItem] &&
                this.filesToUpload[this.activatedItem][this.fileOrderIndex] &&
                this.filesToUpload[this.activatedItem][this.fileOrderIndex].attachmentFilename
              ) {
                this.filesToUpload[this.activatedItem][this.fileOrderIndex].dateOfService = dValue;
                this.fileUploaded = {};
              } else {
                this.filesToUpload[this.activatedItem][this.fileOrderIndex] = {
                  dateOfService: dValue
                };
                this.fileUploaded = {};
                this.fileToUpload = undefined;
              }
            }
          }, 200);
        }
      }
    },
    getSubChecked(obj) {
      let allVs = [];
      for (let key in obj) {
        if (key !== "exclusion") {
          allVs.push(obj[key].value);
        }
      }
      return allVs.some(x => x === true);
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    initialSubmit(changingStatus) {
      if (changingStatus == "approve" || changingStatus == "reject") {
        this.updateEntry(changingStatus);
      } else {
        this.errorMsgs = [];

        this.subset = pick(this.payload, this.selectedFields);

        if (!this.selectedFields.length) {
          this.errorMsgs.push("No selections have been made");
        }
        this.selectedFields.forEach(key => {
          if (this.filesToUpload[key]) {
            Object.keys(this.filesToUpload[key]).forEach(k => {
              const found = this.payload[key].attachments.findIndex(
                x => x.dateOfService == this.filesToUpload[key][k].dateOfService
              );
              if (found > -1) {
                this.payload[key].attachments.splice(found, 0, this.filesToUpload[key][k]);
              } else {
                this.payload[key].attachments.push(this.filesToUpload[key][k]);
              }
            });
          }

          const uniqueAttachs = uniqWith(
            this.payload[key].attachments,
            (atchA, atchB) => atchA.attachmentUrl == atchB.attachmentUrl
          );
          this.payload[key].attachments = uniqueAttachs;

          let hasNoDates = this.payload[key].attachments.some(atch => !atch.dateOfService);
          let hasAttachmentFiles = false;

          if (!this.payload[key].attachments.length || hasNoDates) {
            this.errorMsgs.push(
              `Missing Date of Service for <b>${this.clinicalFormData.entries[key].displayName}</b>`
            );
            this.payload[key].attachments.pop();
          } else {
            hasAttachmentFiles = this.payload[key].attachments.some(atch => atch.attachmentUrl);
          }

          if (
            this.payload[key].attachments.length &&
            !hasAttachmentFiles &&
            this.clinicalFormData.entries[key].supportingFiles.required
          ) {
            this.errorMsgs.push(
              `Missing attachments for <b>${this.clinicalFormData.entries[key].displayName}</b>`
            );
            this.payload[key].attachments.pop();
          }

          let { minDate, maxDate } = this.clinicalFormData.entries[key].supportingFiles;
          this.payload[key].attachments.forEach(attachedFile => {
            let { dateOfService } = attachedFile;
            minDate = minDate.replaceAll("-", "/");
            maxDate = maxDate.replaceAll("-", "/");
            if (
              new Date(dateOfService).getTime() < new Date(minDate).getTime() ||
              new Date(dateOfService).getTime() > new Date(maxDate).getTime()
            ) {
              this.errorMsgs.push("Dates are out of bounds (" + minDate + " to " + maxDate + ")");
              this.payload[key].attachments.pop();
            }
          });

          for (let subKey in this.payload[key]) {
            if (subKey !== "exclusion" && subKey !== "attachments") {
              if (
                this.clinicalFormData.entries[key].detailedEvidence[subKey] &&
                this.clinicalFormData.entries[key].detailedEvidence[subKey].validations &&
                this.clinicalFormData.entries[key].detailedEvidence[subKey].validations.min != null
              ) {
                if (
                  this.payload[key][subKey].value <
                    this.clinicalFormData.entries[key].detailedEvidence[subKey].validations.min ||
                  this.payload[key][subKey].value >
                    this.clinicalFormData.entries[key].detailedEvidence[subKey].validations.max
                ) {
                  this.errorMsgs.push("Values entered are not within bounds of min and max");
                  this.payload[key].attachments.pop();
                }
              }

              if (
                this.clinicalFormData.entries[key].detailedEvidence[subKey]
                  .serviceProviderNpiRequired &&
                (!this.payload[key][subKey].serviceProviderNpi ||
                  isNaN(this.payload[key][subKey].serviceProviderNpi) ||
                  this.payload[key][subKey].serviceProviderNpi.length != 10)
              ) {
                this.errorMsgs.push("Provider NPI is required and must be 10 numeric digits");
                this.payload[key].attachments.pop();
              }

              if (
                this.clinicalFormData.entries[key].detailedEvidence[subKey]
                  .serviceProviderTypeValues &&
                this.clinicalFormData.entries[key].detailedEvidence[subKey]
                  .serviceProviderTypeValues.length &&
                !this.payload[key][subKey].serviceProviderTypeCode
              ) {
                this.errorMsgs.push("Provider Type is required");
                this.payload[key].attachments.pop();
              }
            }
          }

          if (this.item.meas_id == "1053") {
            //Diabetic Kidney Health Evaluation
            if (
              this.item.rec_id == "5184" &&
              (!this.selectedFields.includes("estimatedGlomerularFiltrationRateEgfr") ||
                !this.selectedFields.includes("urineAlbuminCreatinineRatioUacr"))
            ) {
              this.errorMsgs.push("Both eGFR and uACR are required entries");
            }
            if (
              this.item.rec_id == "5185" &&
              !this.selectedFields.includes("estimatedGlomerularFiltrationRateEgfr")
            ) {
              this.errorMsgs.push("The eGFR is required as an entry");
            }
            if (
              this.item.rec_id == "5186" &&
              !this.selectedFields.includes("urineAlbuminCreatinineRatioUacr")
            ) {
              this.errorMsgs.push("The uACR is required as an entry");
            }
          }
        });

        if (this.errorMsgs.length) {
          return;
        }

        this.sendDataToBackend();
      }
    },
    sendDataToBackend() {
      const payload = {
        beneficiaryId: this.beneId,
        gapId: this.item.gapId,
        formId: this.item.cdeFormId,
        data: {
          entries: this.subset
        }
      };

      let method;
      let path;

      if (this.editMode) {
        method = "put";
        path = document.config.putCdeRequest + this.item.cdeRequestId;
      } else {
        method = "post";
        path = document.config.postCdeRequest;
      }

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Submitted form",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          EventBus.$emit("updatingGap");
          EventBus.$emit("QCFormSubmitted", this.item.cdeFormId);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to submit form",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    updateEntry(stat) {
      this.$ionic.modalController
        .create({
          component: ModalUpdateGapEntry,
          mode: "ios",
          componentProps: {
            propsData: {
              entryType: stat,
              requestId: this.item.cdeRequestId
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    },
    approve() {
      const method = "put";
      const path = document.config.putCdeRequest + this.item.cdeRequestId + "/approve";
      const payload = {
        reason: "approved"
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Your Approval has been recorded",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          EventBus.$emit("statusChanged", "approve");
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Approval Error. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    fileSelected(evt) {
      const { allowedFileTypes } = this.clinicalFormData.entries[
        this.activatedItem
      ].supportingFiles;
      const stringfiedFileTypes = allowedFileTypes.join(", ");
      if (evt.target.value) {
        const file = evt.target.files[0];
        if (this.isRightTypeFile(file.name)) {
          if (fileSizeInRange(file)) {
            this.fileToUpload = file;
            this.error = undefined;
            this.showUploadBtn = true;
            this.isDropping = true;
            this.attachFile();
          } else {
            this.$ionic.toastController
              .create({
                header: "Failed to upload file",
                message: "File size must be greater than 0MB and can not exceed 20MB",
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());
          }
        } else {
          this.$ionic.toastController
            .create({
              header: "Failed to upload file",
              message: `This file type is not allowed. Please choose a properly formatted ${stringfiedFileTypes} to upload`,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
        }
      }
    },
    isRightTypeFile(fileName) {
      const fileExtension = fileName
        .toLowerCase()
        .split(".")
        .pop()
        .toString();

      if (
        this.clinicalFormData.entries[this.activatedItem].supportingFiles.allowedFileTypes.indexOf(
          fileExtension
        ) > -1
      ) {
        return true;
      } else {
        return false;
      }
    },
    dropHandler(ev) {
      const { allowedFileTypes } = this.clinicalFormData.entries[
        this.activatedItem
      ].supportingFiles;
      const stringfiedFileTypes = allowedFileTypes.join(", ");
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            var file = ev.dataTransfer.items[i].getAsFile();
            if (this.isRightTypeFile(file.name)) {
              if (fileSizeInRange(file)) {
                this.fileToUpload = file;
                this.error = undefined;
                this.showUploadBtn = true;
                this.attachFile();
              } else {
                this.$ionic.toastController
                  .create({
                    header: "Failed to upload file",
                    message: "File size must be greater than 0MB and can not exceed 20MB",
                    duration: 7000,
                    position: "top"
                  })
                  .then(m => m.present());
              }
            } else {
              this.$ionic.toastController
                .create({
                  header: "Failed to upload file",
                  message: `This file type is not allowed. Please choose a properly formatted ${stringfiedFileTypes} to upload`,
                  duration: 7000,
                  position: "top"
                })
                .then(m => m.present());
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          this.error = "File failed to attach to data transfer service.";
        }
      }
    },
    attachFile() {
      //grab file
      let payload = new FormData();
      payload.append("file", this.fileToUpload);

      this.isUploading = true;

      const method = "post";
      const path = document.config.postCdeAttachment;

      httpSend({ path, method, authToken: true, payload })
        .then(response => {
          this.isDropping = false;

          if (this.filesToUpload[this.activatedItem][this.fileOrderIndex]) {
            const dos = this.filesToUpload[this.activatedItem][this.fileOrderIndex].dateOfService;

            delete this.filesToUpload[this.activatedItem][this.fileOrderIndex];

            this.filesToUpload[this.activatedItem][this.fileOrderIndex] = {
              dateOfService: dos,
              attachmentUrl: response.data.url,
              attachmentFilename: response.data.filename
            };
          } else {
            this.filesToUpload[this.activatedItem][this.fileOrderIndex] = {
              attachmentUrl: response.data.url,
              attachmentFilename: response.data.filename
            };

            // TODO what about this?
            this.payloadChanged = !this.payloadChanged;
          }

          this.isUploading = false;

          if (response.data.totalFailed > 0 && response.data.failureMessages.length > 0) {
            this.totalFailed = response.data.totalFailed;
            this.warnings = response.data.failureMessages;
            this.isUploading = false;
            this.isDropping = false;

            this.$ionic.toastController
              .create({
                header: "Error",
                message: "Failed to attach a file",
                duration: 7000,
                mode: "ios"
              })
              .then(m => m.present());

            this.fileToUpload = undefined;
          }
        })
        .catch(error => {
          this.error = error;
          this.isDropping = false;
          this.isUploading = false;
          this.$ionic.toastController
            .create({
              header: "There was an issue attaching the file. Please try again.",
              message: error,
              duration: 7000,
              mode: "ios"
            })
            .then(m => m.present());
        });
      this.resetUploadState();
    },
    resetUploadState() {
      this.error = undefined;
      this.warnings = undefined;
      this.showUploadBtn = false;
      this.fileToUpload = undefined;
      this.totalFailed = undefined;
    },
    getCdeStatus(cdeStatus) {
      return cdeStatus.replaceAll("_", " ");
    }
  },
  computed: {
    isSaveEnable() {
      if (this.invalidDate && moment(this.inputValue)._d == "Invalid Date") return true;
      else return false;
    },
    isReadyForEntry() {
      return this.selectedFields.length || !this.invalidDate;
    },
    isReviewEnabled() {
      const editFirstReviewMode =
        ["first_review_needed", "in_first_review"].includes(this.item.cdeStatus) &&
        (can(I.REVIEW_CDE_REQUEST) || can(I.REVIEW_CDE_REQUEST_FIRST));

      const editSecondReviewMode =
        ["second_review_needed", "in_second_review"].includes(this.item.cdeStatus) &&
        (can(I.REVIEW_CDE_REQUEST) || can(I.REVIEW_CDE_REQUEST_SECOND));

      return editFirstReviewMode || editSecondReviewMode;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-divider-no-margin {
  border-right: 2px solid var(--ion-color-light);
}

h6 {
  margin: 2px;
}

.evidence-cards {
  margin: 15px;
  padding: 0;
  list-style-type: none;
}
.evidence-cards > li {
  list-style-type: none;
  margin: 5px 5px 5px 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 4px -2px rgba(46, 61, 73, 0.1);
  border: 1px solid #cccccc40;
  background: var(--ion-color-light);
}
.evidence-cards > li ion-col:last-child {
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.evidence-cards > li svg {
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  fill: #aaa;
  position: absolute;
  right: 0;
}

.file-card {
  margin: 5px 5px 5px 0;
  padding: 5px 15px;
  border-radius: 5px;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  height: 45px;

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.card-like {
  box-shadow: 0 4px 4px -2px rgba(46, 61, 73, 0.1);
  border: 1px solid #cccccc40;
  background: var(--ion-color-light);

  .file-name {
    color: var(--ion-color-primary, #3880ff);
  }
}
.file-card > div {
  flex: 1;
}

.file-card > div:nth-child(2) {
  flex: 4;
}

.file-card > div:first-child svg {
  fill: var(--ion-color-primary);
}

ion-content {
  --padding-bottom: 0 !important;
  --padding-end: 0;
  --padding-start: 0;
}

ion-checkbox {
  background: transparent;
}

label {
  vertical-align: top;
  margin-left: 10px;
}

.select-label {
  margin: 10px 0;
  font-weight: bold;
  display: block;
}

ion-footer {
  padding: 15px;
}

ion-checkbox {
  --border-radius: 50%;
}

.activated {
  border: 2px solid var(--ion-color-primary) !important;
}
.activated svg {
  fill: var(--ion-color-primary) !important;
}

svg {
  fill: var(--ion-color-primary);
}

.btn-mid {
  color: var(--ion-color-white);
  height: 40px;
  font-weight: 700;
  /* padding-right: 10px;
  padding-left: 10px; */
  border-radius: 10px;
  box-shadow: var(--prime-button-shadow);
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.reject-button {
  background-color: var(--ion-color-danger);
  &:disabled {
    background: none;
    border: 2px solid #d4d6d8;
    color: #d4d6d8;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.approve-button {
  background-color: var(--ion-color-success-tint);
  &:disabled {
    background: none;
    border: 2px solid #d4d6d8;
    color: #d4d6d8;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.button-mid-large {
  width: 100%;
}

.reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reset-list li {
  margin: 10px;
}

.prime-v-date-picker >>> .vc-popover-content-wrapper {
  transform: scale(0.65) translate3d(0px, -50px, 0px) !important;
}
.prime-v-date-picker span >>> .vc-popover-content-wrapper {
  transform: scale(0.65) translate3d(0px, -50px, 0px) !important;
}
.prime-v-date-picker-container >>> .vc-popover-content-wrapper {
  /*  transform: scale(0.9) translate3d(-80px, -12px, 0px) !important; */
  z-index: 5000;
}

.date-picker-height {
  height: 45px;
  .form-control {
    height: 45px;
  }
}

.btn-mid svg {
  width: 20px;
  height: 20px;
  fill: var(--ion-color-white);
  vertical-align: middle;
}

.prime-item {
  all: revert;
  --padding-start: 0;
  --border-style: none;
  --min-height: 2rem;
}

.prime-item ion-checkbox {
  margin: 0;
}

.drag-and-drop {
  border: 2px dashed #dadada;
}
.drag-and-drop.dropping {
  border: 2px dashed var(--ion-color-primary);
  background-color: #f0f0f0;
}
.prime-file-upload {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.icon-muted {
  fill: var(--ion-color-medium-shade);
}

.discheck,
:host(.checkbox-disabled) {
  opacity: 1 !important;
}

.file-upload-sect {
  margin: 0;
  text-align: left;
  display: inline-block;
  width: 105%;
}

.select-options {
  font-size: 0.9rem;
}

.file-name {
  flex: 9 1 0%;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.danger-well {
  border-radius: 8px;
  margin: 0 20px 20px 10px;
}

.upload-2 {
  text-overflow: ellipsis;
  max-width: 171px;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 5px;
  font-size: 12px;
  color: var(--ion-color-primary, #3880ff);
}
</style>
